import { documentoStore } from './../../store/modules/documentos/index';
import { defineComponent, onMounted, reactive, ref, defineAsyncComponent } from 'vue';
import { DxAccordion } from 'devextreme-vue/ui/accordion';
import { DxForm, DxSimpleItem, DxButtonItem, DxRequiredRule, DxCustomRule } from 'devextreme-vue/ui/form';
import { DxTabPanel, DxItem } from 'devextreme-vue/ui/tab-panel'
import { DxLoadPanel } from 'devextreme-vue/ui/load-panel';
import { meses } from '../../helpers/data-filter.json';
import Validators from '../../helpers/validators-input';

export default defineComponent({
  name: 'RecebimentoXMl',
  setup() {
    const store = documentoStore();
    const form_accordion = ref();

    const state = reactive({
      items: [{ title: 'Filtrar Movimento' }],
      isLoading: false,
      filtro: {
        Cnpj: '',
        Mes: new Date().getMonth() + 1,
        Ano: new Date().getFullYear(),
      },
      anos: Validators.rangeAno(new Date().getFullYear(), new Date().getFullYear() - 10, -1),
      cardItems: [
        { id: 0, title: 'Recebidos', icon: 'mdi mdi-download', value: 0 },
        { id: 1, title: 'Não Processados', icon: 'mdi mdi-close-thick', value: 0 },
        { id: 2, title: 'Processados', icon: 'mdi mdi-cogs', value: 0 },
        { id: 3, title: 'Sem Erros', icon: 'mdi mdi-check-bold', value: 0 },
        { id: 4, title: 'Com Erros', icon: 'mdi mdi-alert', value: 0 },
        { id: 5, title: 'Aguardando', icon: 'mdi mdi-clock', value: 0, },
      ],
      itemSelected: 0
    })

    const filterButtonProperties = {
      text: 'Filtrar',
      type: 'default',
      stylingMode: 'contained',
      icon: 'mdi mdi-filter',
      useSubmitBehavior: true
    }

    const refreshCardsProperties = {
      text: 'Atualizar Cards',
      icon: 'mdi mdi-refresh',
      hint: 'Atualizar Cards',
      onClick: () => GetDadosRecebimentoXml()
    }

    function validateCnpj(e: any) {
      return Validators.valida_cnpj(e.value)
    }

    async function GetDadosRecebimentoXml() {
      const response = await store.GET_DADOS_RECEBIMENTO_XML();
      state.cardItems[0].value = response.quantidadeXml;
      state.cardItems[1].value = response.naoProcessados;
      state.cardItems[2].value = response.processados;
      state.cardItems[3].value = response.semErro;
      state.cardItems[4].value = response.comErro;
      state.cardItems[5].value = response.canceladosAguardando;
    }

    async function Filter() {
      state.isLoading = true;
      store.filter = Object.assign(state.filtro, {Modelo: 0, Tipo: 0})
      try {
        await store.GET_LISTA_RECEBIMENTO_XML(state.filtro.Cnpj, state.filtro.Mes, state.filtro.Ano);
        await store.GET_LISTA_RECEBIMENTO_XML_NAO_PROCESSADO(state.filtro.Cnpj, state.filtro.Mes, state.filtro.Ano);
        await store.GET_LISTA_RECEBIMENTO_XML_PROCESSADO(state.filtro.Cnpj, state.filtro.Mes, state.filtro.Ano);
        await store.GET_LISTA_RECEBIMENTO_XML_SEM_ERRO(state.filtro.Cnpj, state.filtro.Mes, state.filtro.Ano);
        await store.GET_LISTA_RECEBIMENTO_XML_COM_ERRO(state.filtro.Cnpj, state.filtro.Mes, state.filtro.Ano);
        await store.GET_LISTA_RECEBIMENTO_XML_AGUARDANDO(state.filtro.Cnpj, state.filtro.Mes, state.filtro.Ano);
        state.isLoading = false
      } catch (error: any) {
        return error
      }
      form_accordion.value.instance.collapseItem(0)
    }

    async function selectItem(id: number) {
      state.isLoading = true;
      state.itemSelected = id;
      switch (id) {
        case 0:
          await store.GET_LISTA_RECEBIMENTO_XML();
          break;
        case 1:
          await store.GET_LISTA_RECEBIMENTO_XML_NAO_PROCESSADO();
          break;
        case 2:
          await store.GET_LISTA_RECEBIMENTO_XML_PROCESSADO();
          break;
        case 3:
          await store.GET_LISTA_RECEBIMENTO_XML_SEM_ERRO();
          break;
        case 4:
          await store.GET_LISTA_RECEBIMENTO_XML_COM_ERRO();
          break;
        case 5:
          await store.GET_LISTA_RECEBIMENTO_XML_AGUARDANDO();
          break;
      }
      state.isLoading = false;
    }

    onMounted(() => {
      GetDadosRecebimentoXml();
    })

    return {
      state,
      store,
      filterButtonProperties,
      refreshCardsProperties,
      meses,
      form_accordion,
      validateCnpj,
      Filter,
      selectItem,
    }
  },
  components: {
    DxAccordion,
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxRequiredRule,
    DxCustomRule,
    DxTabPanel,
    DxItem,
    DxLoadPanel,
    Recebidos: defineAsyncComponent(() => import('../../components/tabs-recebimento-xml/recebidos/index.vue')),
    NaoProcessadas: defineAsyncComponent(() => import('../../components/tabs-recebimento-xml/nao-processados/index.vue')),
    Processadas: defineAsyncComponent(() => import('../../components/tabs-recebimento-xml/processados/index.vue')),
    ComErro: defineAsyncComponent(() => import('../../components/tabs-recebimento-xml/com-erro/index.vue')),
    SemErro: defineAsyncComponent(() => import('../../components/tabs-recebimento-xml/sem-erro/index.vue')),
    Aguardando: defineAsyncComponent(() => import('../../components/tabs-recebimento-xml/aguardando/index.vue'))
  }
})