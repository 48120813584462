<template>
  <div>
    <p class="title">Recebimentos XML</p>

    <div class="dx-card grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-3 p-3">
      <div class="card-item dx-card transform hover:scale-110 motion-reduce:transform-none cursor-pointer" 
        v-for="item in state.cardItems"
        :key="item"
        @click="selectItem(item.id)"
        ref="cardItem"
      >
        <div class="h-8 bg-brand-accent p-1">
          <i :class="[item.icon, 'text-white']"></i>
          <span class="text-white ml-2 text-base">{{ item.title }}</span>
        </div>
        <div class="flex justify-end mb-1 mr-1 items-baseline">
          <span class="text-3xl mr-1">{{ item.value }}</span> xmls
        </div>
      </div>
    </div>

    <DxAccordion
      ref="form_accordion"
      :collapsible="true"
      :data-source="state.items"
      item-title-template="title-accordion"
      item-template="item"
    >
      <template #title-accordion>Filtrar Movimento:</template>
      <template #item>
        <form @submit.prevent="Filter">
          <DxForm
            :form-data="state.filtro"
            :show-colon-after-label="true"
            :min-col-width="100"
            :col-count-by-screen="{ lg: 5, md: 5, sm: 1, xs: 1 }"
            col-count="auto"
          >
            <DxSimpleItem
              data-field="Cnpj"
              :label="{ text: 'CNPJ' }"
              :editor-options="{ mask: '00.000.000/0000-00' }"
            >
              <DxRequiredRule />
              <DxCustomRule
                message="Documento inválido"
                type="custom"
                :reevaluate="true"
                :validation-callback="validateCnpj"
              ></DxCustomRule>
            </DxSimpleItem>

            <DxSimpleItem
              data-field="Mes"
              :label="{ text: 'Mês' }"
              editor-type="dxSelectBox"
              :editor-options="{
                items: meses,
                displayExpr: 'name',
                valueExpr: 'id',
                value: state.filtro.Mes,
              }"
            />

            <DxSimpleItem
              data-field="Ano"
              editor-type="dxSelectBox"
              :editor-options="{
                items: state.anos,
                value: state.filtro.Ano,
              }"
            />

            <DxButtonItem
              :button-options="filterButtonProperties"
              vertical-alignment="center"
              css-class="mt-4"
            />
            
            <DxButtonItem 
              :button-options="refreshCardsProperties"
              vertical-alignment="center"
              css-class="mt-4"
              />
          </DxForm>
        </form>
      </template>
    </DxAccordion>

    <DxTabPanel
      :show-nav-buttons="true"
      :repaint-changes-only="true"
      :animation-enabled="true"
      :selected-index="state.itemSelected"
    >
      <DxItem title="Recebidos" icon="mdi mdi-download" >
        <Recebidos/>
      </DxItem>

      <DxItem title="Não Processados" icon="mdi mdi-close-thick">
        <NaoProcessadas/>
      </DxItem>

      <DxItem title="Processados" icon="mdi mdi-cogs">
       <Processadas/>
      </DxItem>

      <DxItem title="Sem Erro" icon="mdi mdi-check-bold">
        <SemErro/>
      </DxItem>

      <DxItem title="Com Erro" icon="mdi mdi-alert">
       <ComErro/>
      </DxItem>

      <DxItem title="Aguardando" icon="mdi mdi-clock">
        <Aguardando/>
      </DxItem>

    </DxTabPanel>

    <DxLoadPanel
      :visible="state.isLoading"
      :show-pane="true"
      :shading="true"
      shading-color="rgba(255,255,255, 0.8)"
    />
  </div>
</template>
<script src="./index.ts"></script>

<style lang="postcss" scoped>
.card-item {
  @apply text-black flex flex-col justify-between h-20;
  border-radius: 0px 10px 0px 10px;
}
</style>
